import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildConfigurationSortProjectActivities } from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const reorderProjectActivities = (
    projectId: string,
    payload: Record<string, number>,
): Observable<any> => {
    const url = buildConfigurationSortProjectActivities(projectId);
    return sendRequestWithXSRFToken(url, payload, 'PUT').pipe(switchMap(() => observableOf({})));
};
